import gql from "graphql-tag";

export const Notifications = {
    Queries: {
        Notifications: gql`
        query notifications($userId: ID, $hostIds: [ID], $productId: ID, $createdBy: ID, $notificationPriorityIds: [ID], $notificationTypeId: ID, $isSeen: Boolean, $isDone: Boolean, $from: Date, $to: Date, $offset: Int, $limit: Int, $order: String) {
            notifications(userId: $userId, hostIds: $hostIds, productId: $productId, createdBy: $createdBy, notificationPriorityIds: $notificationPriorityIds, notificationTypeId: $notificationTypeId, isSeen: $isSeen, isDone: $isDone, from: $from, to: $to, offset: $offset, limit: $limit, order: $order) {
                Id
                Guid
                Title
                Message
                CreatedAt
                IsSeen
                SeenAt
                SeenBy
                RedirectUrl
                NotificationDate
                UserId
                HostId
                ProductId
                UserGroupId
                CreatedBy
                UpdatedBy
                IsDone
                DoneAt
                DoneBy
                NotificationPriorityId
                SourceUserId
                SourceUserGroupId
                ProductStatusAssignmentId
            }
        }`,
        NotificationsCount: gql`
        query notificationsCount($userId: ID, $hostIds: [ID], $productId: ID, $createdBy: ID, $notificationPriorityIds: [ID], $notificationTypeId: ID, $isSeen: Boolean, $isDone: Boolean, $from: Date, $to: Date) {
            notificationsCount(userId: $userId, hostIds: $hostIds, productId: $productId, createdBy: $createdBy, notificationPriorityIds: $notificationPriorityIds, notificationTypeId: $notificationTypeId, isSeen: $isSeen, isDone: $isDone, from: $from, to: $to)
        }`,
        Notification: gql`
        query notification($id: ID) {
            notification(id: $id) {
                Id
                Guid
                Title
                Message
                CreatedAt
                IsSeen
                SeenAt
                SeenBy
                RedirectUrl
                NotificationDate
                UserId
                HostId
                ProductId
                UserGroupId
                CreatedBy
                UpdatedBy
                IsDone
                DoneAt
                DoneBy
                NotificationPriorityId
                SourceUserId
                SourceUserGroupId
                ProductStatusAssignmentId
            }
        }`,
    },
    Mutations: {
        SaveNotification: gql`
        mutation saveNotification($notification: NotificationsInput) {
            saveNotification(notification: $notification) {
                IsSuccess
                Message
            }
        }`,
        SetAllNotificationsAsSeen: gql`
        mutation setAllNotificationsAsSeen($seenBy: ID, $userId: ID, $productId: ID, $hostIds: [ID], $createdBy: ID) {
            setAllNotificationsAsSeen(seenBy: $seenBy, userId: $userId, productId: $productId, hostIds: $hostIds, createdBy: $createdBy) {
                IsSuccess
                Message
            }
        }`,
        SetAllNotificationsAsDone: gql`
        mutation setAllNotificationsAsDone($doneBy: ID, $userId: ID, $productId: ID, $hostIds: [ID], $createdBy: ID) {
            setAllNotificationsAsDone(doneBy: $doneBy, userId: $userId, productId: $productId, hostIds: $hostIds, createdBy: $createdBy) {
                IsSuccess
                Message
            }
        }`,
    }
};

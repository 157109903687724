<template lang="pug">
  .row
    .col-md.col-sm-12.d-flex.align-self-center
      .form-check
        input.form-check-input(type="radio" id="done" :value="true" :checked="isDone" v-model="isDone" @change="filterNotifications")
        label.form-check-label(for="done") erledigt
      .form-check.ml-2
        input.form-check-input(type="radio" id="notDone" :value="false" :checked="isDone == false" v-model="isDone" @change="filterNotifications")
        label.form-check-label(for="notDone") nicht erledigt
      .form-check.ml-2
        input.form-check-input(type="radio" id="doneAndNotDone" :value="null" :checked="isDone == null" v-model="isDone" @change="filterNotifications")
        label.form-check-label(for="doneAndNotDone") erledigt & nicht erledigt

    .col-md.col-sm-12.d-flex.align-items-center.justify-content-md-center.justify-content-sm-start
      .form-check
        input.form-check-input(type='radio' id="tillNow" :value="1" v-model="notificationTimeSettings")
        label.form-check-label(for="tillNow") bis jetzt
      .form-check.ml-2
        input.form-check-input(type='radio' id="all" :value='2' v-model="notificationTimeSettings")
        label.form-check-label(for="all") alle

    .col-md.col-sm-12
      select.form-control(v-model="order" @change="filterNotifications")
        option(value="Id") Aktualität
        option(value="NotificationDate") Benachrichtigungsdatum

    //.col-md.col-sm-12
      .float-right
        .d-flex.align-self-center
          .form-check
            input.form-check-input(type="checkbox" id="normal" :value="1" :checked="notificationPriorityIds.includes(1)" v-model="notificationPriorityIds" @change="filterNotifications")
            label.form-check-label(for="normal") normal
          .form-check.ml-2
            input.form-check-input(type="checkbox" id="urgent" :value="2" :checked="notificationPriorityIds.includes(2)" v-model="notificationPriorityIds" @change="filterNotifications")
            label.form-check-label(for="urgent") dringend
    .col-12.mt-3
      notifications-table(
        :notifications="notifications"
        :notifications-to-delete="notificationsToDelete"
        :select-all-notifications="selectAllNotifications"
        :set-notifications-as-done="setNotificationsAsDone"
        :open-notification="openNotificationDetailsModal"
        :handle-deletion="handleDeletion"
      )
      pagination(
        :load="loadNotifications"
        :overall-count="notificationsCount"
        :offset="offset"
        :limit="limit"
        query-offset-name="notificationsOffset"
      )

    .col-12.mt-3
    .col-md-6.col-sm-12.mt-3
      button.button.button-primary.button-tbook(@click="setAllNotificationsAsDone") alle Benachrichtigungen erledigen
    .col-md-6.col-sm-12.mt-3
      .float-md-right
        button.button.button-primary.button-tdays(@click="openNotification(undefined, hostId, productId)") neue Benachrichtigung erstellen

</template>

<script>
import EventBus from "@/event-bus";
import { Notifications } from "@/graphql/notifications/Notifications.ts";
import Pagination from "@/views/partials/pagination.vue";
import NotificationDetailsModal from "@/views/notifications/NotificationDetailsModal.vue";
import { NotificationTypesEnum } from "@/utils/enums/notificationTypes/NotificationTypesEnum.ts";
import { NotificationsComponent } from "@/lib/components/notifications/NotificationsComponent.ts";
import moment from "moment/moment";
import NotificationsTable from "@/views/notifications/NotificationsTable.vue";

export default {
  name: "Notifications",
  components: { NotificationsTable, Pagination },
  props: {
    userId: {
      type: [String, Number],
      required: false,
    },
    createdBy: {
      type: [String, Number],
      required: false,
    },
    hostId: {
      type: [String, Number],
      required: false,
    },
    productId: {
      type: [String, Number],
      required: false,
    },
    openNotification: {
      type: Function,
      required: true,
    },
  },
  data() {
    return {
      notifications: [],
      notificationsCount: 0,
      urgentNotifications: [],

      notificationPriorityIds: [1, 2],
      isDone: false,
      notificationTimeSettings: 1,
      to: moment().add(15, "minutes").toDate(),

      offset: 0,
      limit: 15,
      order: "Id",

      notificationsToDelete: [],
      moment: moment,
    };
  },
  async mounted() {
    try {
      if (this.$router.currentRoute.query.notificationsOffset) {
        this.offset = parseInt(
          this.$router.currentRoute.query.notificationsOffset
        );
      }
      EventBus.$on(
        "updateNotifications",
        function () {
          this.notifications = [];
          this.offset = 0;
          this.loadNotifications();
          this.loadNotificationsCount();
        }.bind(this)
      );
      this.loadNotifications();
      this.loadNotificationsCount();
    } catch (e) {
      console.error(e);
      this.$alert(e.message);
    }
  },
  methods: {
    async loadNotifications(offset) {
      try {
        if (
          !this.userId &&
          !this.createdBy &&
          !this.productId &&
          !this.hostId
        ) {
          return;
        }
        if (offset != undefined) {
          this.offset = offset;
        }
        const notificationsComponent = new NotificationsComponent();
        EventBus.$emit("changeLoadingState", true);
        const notifications = await notificationsComponent.getNotifications(
          this.$apollo,
          this.userId,
          this.hostId ? [this.hostId] : undefined,
          this.productId,
          this.createdBy,
          this.notificationPriorityIds,
          NotificationTypesEnum.ProductManager,
          undefined,
          this.isDone,
          undefined,
          this.to,
          this.offset,
          this.limit,
          this.order
        );
        EventBus.$emit("changeLoadingState", false);
        if (!notifications) {
          return this.$alert(
            "Es konnten keine Benachrichtigungen geladen werden."
          );
        }
        this.notifications = notifications;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    async loadNotificationsCount() {
      try {
        if (
          !this.userId &&
          !this.createdBy &&
          !this.productId &&
          !this.hostId
        ) {
          return;
        }
        const notificationsComponent = new NotificationsComponent();
        const notificationsCount =
          await notificationsComponent.getNotificationsCount(
            this.$apollo,
            this.userId,
            this.hostId ? [this.hostId] : undefined,
            this.productId,
            this.createdBy,
            this.notificationPriorityIds,
            NotificationTypesEnum.ProductManager,
            undefined,
            this.isDone,
            undefined,
            this.to
          );
        if (notificationsCount == undefined) {
          return;
        }
        this.notificationsCount = notificationsCount;
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
    filterNotifications() {
      try {
        this.notifications = [];
        this.offset = 0;
        this.loadNotifications();
        this.loadNotificationsCount();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    handleDeletion(notification) {
      try {
        if (!notification) {
          return;
        }
        if (this.notificationsToDelete.some((n) => n.Id == notification.Id)) {
          return this.notificationsToDelete.splice(
            this.notificationsToDelete.indexOf(notification),
            1
          );
        }
        this.notificationsToDelete.push(notification);
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async setNotificationsAsDone() {
      try {
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie die Benachrichtigungen als erledigt markieren wollen?"
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        EventBus.$emit("changeLoadingState", true);
        for (const notification of this.notificationsToDelete) {
          notification.IsDone = true;
          notification.DoneAt = new Date();
          notification.DoneBy = this.$session.get("userId");
          notification.UpdatedBy = this.$session.get("userId");
          const savedNotification = await this.$apollo
            .mutate({
              mutation: Notifications.Mutations.SaveNotification,
              fetchPolicy: "no-cache",
              variables: {
                notification: notification,
              },
            })
            .then(({ data }) => data?.saveNotification)
            .catch((e) => {
              console.error(e);
              return undefined;
            });
          if (!savedNotification) {
            EventBus.$emit("changeLoadingState", false);
            return this.$alert(
              `Benachrichtigung ${notification.Id} konnte nicht gelöscht werden.`
            );
          }
          if (!savedNotification.IsSuccess) {
            EventBus.$emit("changeLoadingState", false);
            return this.$alert(savedNotification.Message);
          }
        }
        EventBus.$emit("changeLoadingState", false);
        this.$swal(
          "Die Benachrichtigungen wurden erfolgreich als erledigt markiert"
        );
        EventBus.$emit("updateNotDoneNotificationsCount");
        this.filterNotifications();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    openNotificationDetailsModal(notificationId, hostId, productId, date) {
      try {
        this.$modal.show(
          NotificationDetailsModal,
          {
            notificationId: notificationId,
            hostId: hostId,
            productId: productId,
            date: date,
          },
          {
            height: "auto",
            classes: ["rounded-0", "w-auto"],
          }
        );
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    selectAllNotifications() {
      try {
        if (
          this.notificationsToDelete.length == 0 ||
          this.notificationsToDelete.length < this.notifications.length
        ) {
          this.notificationsToDelete = this.notifications;
        } else if (
          this.notificationsToDelete.length == this.notifications.length
        ) {
          this.notificationsToDelete = [];
        }
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    async setAllNotificationsAsDone() {
      try {
        const confirmed = await this.$confirm(
          "Sind Sie sicher, dass Sie alle Benachrichtigungen erledigen möchten?"
        )
          .then()
          .catch((e) => e);
        if (!confirmed) {
          return;
        }
        const notificationsComponent = new NotificationsComponent();
        EventBus.$emit("changeLoadingState", true);
        const setAllNotificationsAsDone =
          await notificationsComponent.setAllNotificationsAsDone(
            this.$apollo,
            this.$session.get("userId"),
            this.userId,
            this.hostId,
            this.productId,
            this.createdBy
          );
        EventBus.$emit("changeLoadingState", false);
        if (setAllNotificationsAsDone.Message) {
          if (setAllNotificationsAsDone.IsSuccess) {
            this.$swal(setAllNotificationsAsDone.Message);
          } else {
            this.$alert(setAllNotificationsAsDone.Message);
          }
        }
        if (setAllNotificationsAsDone.IsSuccess) {
          EventBus.$emit("updateNotDoneNotificationsCount");
          this.offset = 0;
          this.notifications = [];
          this.loadNotifications();
          this.loadNotificationsCount();
        }
      } catch (e) {
        console.log(e);
        this.$alert(e.message);
      }
    },
  },
  watch: {
    notificationTimeSettings() {
      try {
        this.to =
          this.notificationTimeSettings == 1
            ? moment().add(15, "minutes").toDate()
            : undefined;
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
    to() {
      try {
        this.loadNotifications();
        this.loadNotificationsCount();
      } catch (e) {
        console.error(e);
        this.$alert(e.message);
      }
    },
  },
};
</script>

<style scoped></style>
